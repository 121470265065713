import React from "react"
import { Row, Col, Container } from "reactstrap"
import Link from "../components/link"
import Layout from "../components/layout"
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFax } from "react-icons/fa"
import Styled from "styled-components"
import PageTitle from "../components/page-title"
import { graphql } from "gatsby"

let Cirle = Styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

let ContactItem = ({ text, type, Icon, href }) => (
  <Col
    xs={6}
    lg={3}
    className="d-flex flex-column align-items-center"
    style={{ wordBreak: "break-word" }}
  >
    <Cirle className="h-4-rem w-4-rem bg-primary-faded my-3 absolute-center rounded-circle">
      <Icon size={30} />
    </Cirle>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? (
          <Link to={href} className="reset">
            {text}
          </Link>
        ) : (
          text
        )}
      </span>
    </div>
  </Col>
)

let Contact = ({ data }) => {
  let { address, phone, fax } = data.site.siteMetadata
  return (
    <Layout>
      <PageTitle title="Kontakt" />
      <Container>
        <Row className="py-5">
          <ContactItem text={address} type="Adresse" Icon={FaMapMarkerAlt} />
          <ContactItem
            text={'anmeldung@dr-joerg.at'}
            href={`mailto:anmeldung@dr-joerg.at`}
            type="Email"
            Icon={FaEnvelope}
          />
          <ContactItem
            text={phone}
            href={`tel:${phone}`}
            type="Telefon"
            Icon={FaPhone}
          />
          <ContactItem text={fax} type="Fax" Icon={FaFax} />
        </Row>
        <hr />
        <Row className="py-5">
          <Col md={6}>
            <p className="bodytext">
              <b>
                Dr. Astrid Jörg-Koutromanos
                <br />
                Ärztin für Allgemeinmedizin
                <br />
                <br />
              </b>
              Markt 180/1
              <br />
              8323 St.Marein bei Graz
              <br />
              <br />
              Tel: <a href="tel:+43311921000">+43 (0) 3119 / 21000</a>
              <br />
              Fax:+43 (0) 3119 / 21000 - 4<br />
              <br />
              Email 1:{" "}
              <a href="mailto:anmeldung@dr-joerg.at">anmeldung@dr-joerg.at</a>
              <br />
              Email 2: <a href="mailto:info@dr-joerg.at">info@dr-joerg.at</a>
              <br />
              <br />
              Web:{" "}
              <a href="https://www.dr-joerg.at/" target="_top">
                www.dr-joerg.at
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        address
        email
        phone
        fax
      }
    }
  }
`

export default Contact
